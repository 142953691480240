const ROUTES = {
  "/": {
    "name": "HomePage140376b83a36469286b5153242c3b23d",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesBe96aa09E3c0498dAd96047abef2d332",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPage853569deF07c4175892140afa1c63077",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/about/leveling": {
    "name": "AboutLevelingPageD53e3926E0fa444e8adb2dbabbdfab68",
    "type": "HTML",
    "key": "about-leveling-page"
  },
  "/contact": {
    "name": "Contact25efd17aAa1047538834E9a24f339d36",
    "type": "HTML",
    "key": "contact"
  },
  "/events/public": {
    "name": "PublicEventsPageC8b36a7dBd8842a59339Da5f9cc66d18",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPageF24f69973503400a8247196966f65b30",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/members/*": {
    "name": "MemberPages296f38917f5e430eB189D12aa0a3d9b1",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePagesC9d678f740504b8bA933D44f4d128195",
    "type": "HTML",
    "key": "message-pages"
  },
  "/resources": {
    "name": "ResourcesPage10840101126c499b939eD383f5b06e8e",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPagesFfab96080fb04d388bfd70a3eba599ee",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;