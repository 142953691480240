import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "4d5c6685-9902-425c-a8c5-5cf98e3f77d5"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage4d5c66859902425cA8c55cf98e3f77d5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage4d5c66859902425cA8c55cf98e3f77d5 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header165c3a52A246493bA452614af6656ae9" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer1201dab103774222899967ad168f865f" {...props} />}
    </div>
  );
}

// id: "27e22c73-c5d5-4056-876d-a975c37d5a2e"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader27e22c73C5d54056876dA975c37d5a2e(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader27e22c73C5d54056876dA975c37d5a2e site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoA0d921428d2c42a4A0dd2aba8d86bb08", parentTag)} label={<DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc parentTag="Label6e918773D90b49d59cd27a6f9f73f433" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("NavigationA0af3b3573224ca78750Edf79cd723ba", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "d53e3926-e0fa-444e-8adb-2dbabbdfab68"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPageD53e3926E0fa444e8adb2dbabbdfab68(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPageD53e3926E0fa444e8adb2dbabbdfab68`}>
      {props["header"] || <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header1853d77d29c842979d979b3d9800bcde" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer0a4bebb6D6424a5eA2a0B24369aa54e5" {...props} />}
    </div>
  );
}

// id: "b3cafaba-128d-454e-a563-1ea12b68d680"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "27e22c73-c5d5-4056-876d-a975c37d5a2e"
export const BackgroundImageB3cafaba128d454eA5631ea12b68d680 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "98c4a8d9-7b29-42ed-9ee7-fb8aa29f5759"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "bfe5bd37-0d17-4dba-bba9-283942725707"
export function Navigation98c4a8d97b2942ed9ee7Fb8aa29f5759(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation98c4a8d97b2942ed9ee7Fb8aa29f5759", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "7568c77f-ad0f-4074-a755-d598980481ad"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "65858246-3e16-4e5c-968b-13af0d903ae3"
export function Hero7568c77fAd0f4074A755D598980481ad(props: any) {
  return (
    <HomePageHeroFb04fb412b244e88Ab5f817aaf1aa6f7 parentTag="Hero7568c77fAd0f4074A755D598980481ad" {...props} />
  );
}

// id: "be96aa09-e3c0-498d-ad96-047abef2d332"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesBe96aa09E3c0498dAd96047abef2d332(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesBe96aa09E3c0498dAd96047abef2d332 gf-page-layout`}>
      {props.children}
    </div>
  );
}

// id: "914b7e5a-ac87-4945-9060-b6bbb9af4ad5"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault914b7e5aAc8749459060B6bbb9af4ad5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault914b7e5aAc8749459060B6bbb9af4ad5 page`}>
      {props["header"] || <MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7 parentTag="HeaderDd8ac83310b94c86B78591656b792598" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer9e9ac86cE9854243BabfEe111e478d9b" {...props} />}
    </div>
  );
}

// id: "b30e5004-a98b-4f2c-bb83-c4a41bced8dc"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png" className={`${parentTag || ""} DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc logo`} alt="logo" />
  );
}

// id: "bf6b40bc-df4a-4c82-b42b-24a2b207858f"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderBf6b40bcDf4a4c82B42b24a2b207858f(props: any) {
  return (
    <ShortHeader27e22c73C5d54056876dA975c37d5a2e parentTag="DefaultHeaderBf6b40bcDf4a4c82B42b24a2b207858f" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "beaebdef-95c6-446f-b53c-5bac75818d47"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteBeaebdef95c6446fB53c5bac75818d47(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteBeaebdef95c6446fB53c5bac75818d47`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "0be07e90-e8ba-43e4-b64b-baba946c59b7"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7(props: any) {
  return (
    <ShortHeader27e22c73C5d54056876dA975c37d5a2e parentTag="MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7" header-top={<DefaultHeaderTopBfe5bd370d174dbaBba9283942725707 parentTag="HeaderTop0589b7f92ff74f9eA5b78064f17a6e61" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "10840101-126c-499b-939e-d383f5b06e8e"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage10840101126c499b939eD383f5b06e8e(props: any) {
  return (
    <SecondaryPage4d5c66859902425cA8c55cf98e3f77d5 parentTag="ResourcesPage10840101126c499b939eD383f5b06e8e" header={<SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header59a4cec2498a430689fc2e1ebdbd4f78" title="Resources" />} {...props} />
  );
}

// id: "02d628df-12ec-4cba-b39c-4815f07bdacb"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody02d628df12ec4cbaB39c4815f07bdacb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody02d628df12ec4cbaB39c4815f07bdacb page-body`}>
      {props["marketingCards"] || <MarketingCards0bd27f986b32487d95f484c4003262b5 parentTag="MarketingCards4db0fe8532974b09B4f3990de21edb32" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "2e6cb9ef-a67b-479b-a3d9-b156e9e6e7d1"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label2e6cb9efA67b479bA3d9B156e9e6e7d1(props: any) {
  return (
    <DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc parentTag="Label2e6cb9efA67b479bA3d9B156e9e6e7d1" {...props} />
  );
}

// id: "f24f6997-3503-400a-8247-196966f65b30"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageF24f69973503400a8247196966f65b30(props: any) {
  return (
    <SecondaryPage4d5c66859902425cA8c55cf98e3f77d5 parentTag="JoinUsPageF24f69973503400a8247196966f65b30" header={<SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header6d1aa071Ed6449298d646e5aff1e0440" title="Join Us" />} {...props} />
  );
}

// id: "853569de-f07c-4175-8921-40afa1c63077"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPage853569deF07c4175892140afa1c63077(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPage853569deF07c4175892140afa1c63077 page gf-page-layout page gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="HeaderC7c1ab316dee41b5B1404706bff90021" title="About Us" {...props} />}
      {props["body"] || <BodyC74bdce9C3084e629f2f5a51e614b6b5 />}
      {props["footer"] || <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer30fc08e7Dfd942a6B374C98f8f12ad02" {...props} />}
    </div>
  );
}

// id: "296f3891-7f5e-430e-b189-d12aa0a3d9b1"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages296f38917f5e430eB189D12aa0a3d9b1(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages296f38917f5e430eB189D12aa0a3d9b1 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7 parentTag="HeaderDaa022f2067143c2B61dEc3b013497f4" {...props} />}
      <main className="main">
        {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav5c9a933f3a674419A42c76ba624a85a0", parentTag)} {...props} />}
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="FooterA441b9d600ff4d6e84e842121d8853ff" {...props} />}
    </div>
  );
}

// id: "510d602d-210a-4a8b-bd23-b59e2939f4d7"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href510d602d210a4a8bBd23B59e2939f4d7 = "/";

// id: "760d77cc-8bda-48c8-aae6-2f553b66fdfd"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer760d77cc8bda48c8Aae62f553b66fdfd(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer760d77cc8bda48c8Aae62f553b66fdfd" {...props} />
  );
}

// id: "ffab9608-0fb0-4d38-8bfd-70a3eba599ee"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPagesFfab96080fb04d388bfd70a3eba599ee(props: any) {
  return (
    <SecondaryPage4d5c66859902425cA8c55cf98e3f77d5 parentTag="SignupPagesFfab96080fb04d388bfd70a3eba599ee" header={<SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header12c2be3eA1744389Acbf472b58f04c28" title="Join Us" />} {...props} />
  );
}

// id: "f91efe72-943f-4064-be95-49e2cabbd3c7"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function HeaderF91efe72943f4064Be9549e2cabbd3c7(props: any) {
  return (
    <MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7 parentTag="HeaderF91efe72943f4064Be9549e2cabbd3c7" {...props} />
  );
}

// id: "c9d678f7-4050-4b8b-a933-d44f4d128195"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePagesC9d678f740504b8bA933D44f4d128195(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePagesC9d678f740504b8bA933D44f4d128195 gf-page-layout`}>
      {props["header"] || <MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7 parentTag="Header9d860754924049c0919c678c5aecb13e" {...props} />}
      {props.children}
    </div>
  );
}

// id: "c8b36a7d-bd88-42a5-9339-da5f9cc66d18"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageC8b36a7dBd8842a59339Da5f9cc66d18(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageC8b36a7dBd8842a59339Da5f9cc66d18 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header545a01bf7a0f4ed1B9fa9b981b8fdd36" title="Public Events" {...props} />}
      <main className="main">
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="FooterA794277aAa1347e9Ac35980632b92df3" {...props} />}
    </div>
  );
}

// id: "65858246-3e16-4e5c-968b-13af0d903ae3"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader658582463e164e5c968b13af0d903ae3(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader658582463e164e5c968b13af0d903ae3 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopBfe5bd370d174dbaBba9283942725707 parentTag="HeaderTop97dced7aEc4848bf823364ce92da08fb" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroFb04fb412b244e88Ab5f817aaf1aa6f7 parentTag="Hero7568c77fAd0f4074A755D598980481ad" {...props} />}
    </div>
  );
}

// id: "d495e45c-ff91-4f95-99c3-130fa6583ef3"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleD495e45cFf914f9599c3130fa6583ef3 = "Leveling";

// id: "4db0fe85-3297-4b09-b4f3-990de21edb32"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "02d628df-12ec-4cba-b39c-4815f07bdacb"
export function MarketingCards4db0fe8532974b09B4f3990de21edb32(props: any) {
  return (
    <MarketingCards0bd27f986b32487d95f484c4003262b5 parentTag="MarketingCards4db0fe8532974b09B4f3990de21edb32" postSlug="home-page-posts2" {...props} />
  );
}

// id: "a441b9d6-00ff-4d6e-84e8-42121d8853ff"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "296f3891-7f5e-430e-b189-d12aa0a3d9b1"
export function FooterA441b9d600ff4d6e84e842121d8853ff(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="FooterA441b9d600ff4d6e84e842121d8853ff" {...props} />
  );
}

// id: "da282f3c-d07c-4138-a28a-66ff4e8fe61e"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "bfe5bd37-0d17-4dba-bba9-283942725707"
export function LinkedLogoDa282f3cD07c4138A28a66ff4e8fe61e(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoDa282f3cD07c4138A28a66ff4e8fe61e", parentTag)} url="/" label={<DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc parentTag="LabelA0d8b0129f004f1784b0946f593a5d41" alt="logo" />} className="linked-logo" {...props} />
  );
}

// id: "12c2be3e-a174-4389-acbf-472b58f04c28"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ffab9608-0fb0-4d38-8bfd-70a3eba599ee"
export function Header12c2be3eA1744389Acbf472b58f04c28(props: any) {
  return (
    <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header12c2be3eA1744389Acbf472b58f04c28" title="Join Us" {...props} />
  );
}

// id: "59a4cec2-498a-4306-89fc-2e1ebdbd4f78"
// title: ""
// type: :reference
// key: "header"
// parent_id: "10840101-126c-499b-939e-d383f5b06e8e"
export function Header59a4cec2498a430689fc2e1ebdbd4f78(props: any) {
  return (
    <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header59a4cec2498a430689fc2e1ebdbd4f78" title="Resources" {...props} />
  );
}

// id: "bcbb2f50-16d0-44d7-bdcd-a8e7a3cf584a"
// title: ""
// type: :text
// key: "href"
// parent_id: "2c5f0f62-f71e-4a48-89ca-8f5f2841e4d0"
export const HrefBcbb2f5016d044d7BdcdA8e7a3cf584a = "/join";

// id: "6f7a0247-9b95-4ba0-bd03-624e68b777b7"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "c5706cc6-56aa-457e-9adb-f0b944e10c23"
export const NavMenuSlug6f7a02479b954ba0Bd03624e68b777b7 = "footer-nav";

// id: "d07ed7df-0f35-401d-887d-c8079483d658"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "65858246-3e16-4e5c-968b-13af0d903ae3"
export const BackgroundImageD07ed7df0f35401d887dC8079483d658 = "https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg";

// id: "9e9ac86c-e985-4243-babf-ee111e478d9b"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "914b7e5a-ac87-4945-9060-b6bbb9af4ad5"
export function Footer9e9ac86cE9854243BabfEe111e478d9b(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer9e9ac86cE9854243BabfEe111e478d9b" {...props} />
  );
}

// id: "b29a19db-5e14-4101-bc8d-94cdfdd2b96e"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "140376b8-3a36-4692-86b5-153242c3b23d"
export function QuoteB29a19db5e144101Bc8d94cdfdd2b96e(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("QuoteB29a19db5e144101Bc8d94cdfdd2b96e", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "1201dab1-0377-4222-8999-67ad168f865f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4d5c6685-9902-425c-a8c5-5cf98e3f77d5"
export function Footer1201dab103774222899967ad168f865f(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer1201dab103774222899967ad168f865f" {...props} />
  );
}

// id: "0a4bebb6-d642-4a5e-a2a0-b24369aa54e5"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "d53e3926-e0fa-444e-8adb-2dbabbdfab68"
export function Footer0a4bebb6D6424a5eA2a0B24369aa54e5(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer0a4bebb6D6424a5eA2a0B24369aa54e5" {...props} />
  );
}

// id: "6285255d-08f0-413a-a78e-88a5db29942c"
// title: ""
// type: :text
// key: "url"
// parent_id: "da282f3c-d07c-4138-a28a-66ff4e8fe61e"
export const Url6285255d08f0413aA78e88a5db29942c = "/";

// id: "90e780e6-fb5e-46d5-a3c0-917957d5e78c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "a0af3b35-7322-4ca7-8750-edf79cd723ba"
export const NavMenuSlug90e780e6Fb5e46d5A3c0917957d5e78c = "members-primary-nav";

// id: "4838a919-76d3-4185-8d3e-0336a0bd1482"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Contact", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopBfe5bd370d174dbaBba9283942725707 parentTag="HeaderTopD3de29c2Acde4a5584d6Ebd36807842c" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHeroDd78fc664bc144a5Bad9B84ef9a4a99d parentTag="HeroEcd1a539E20f4a61B4213e56bfaf8ccd" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "a0af3b35-7322-4ca7-8750-edf79cd723ba"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "27e22c73-c5d5-4056-876d-a975c37d5a2e"
export function NavigationA0af3b3573224ca78750Edf79cd723ba(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("NavigationA0af3b3573224ca78750Edf79cd723ba", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "30fc08e7-dfd9-42a6-b374-c98f8f12ad02"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "853569de-f07c-4175-8921-40afa1c63077"
export function Footer30fc08e7Dfd942a6B374C98f8f12ad02(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer30fc08e7Dfd942a6B374C98f8f12ad02" {...props} />
  );
}

// id: "460e3dfb-6efb-427b-9b69-15c410c19ffd"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "e78135e4-fdc9-453c-829f-41a1cc0a7ea6"
export function Copyright460e3dfb6efb427b9b6915c410c19ffd(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright460e3dfb6efb427b9b6915c410c19ffd", parentTag)} {...props} />
  );
}

// id: "a794277a-aa13-47e9-ac35-980632b92df3"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c8b36a7d-bd88-42a5-9339-da5f9cc66d18"
export function FooterA794277aAa1347e9Ac35980632b92df3(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="FooterA794277aAa1347e9Ac35980632b92df3" {...props} />
  );
}

// id: "43455f0a-e026-45c2-a7a5-85f4836c19db"
// title: ""
// type: :reference
// key: "header"
// parent_id: "140376b8-3a36-4692-86b5-153242c3b23d"
export function Header43455f0aE02645c2A7a585f4836c19db(props: any) {
  return (
    <HomeHeader658582463e164e5c968b13af0d903ae3 parentTag="Header43455f0aE02645c2A7a585f4836c19db" {...props} />
  );
}

// id: "4b413c3c-10ac-4b82-bdc5-1bcbc603fce4"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "b29a19db-5e14-4101-bc8d-94cdfdd2b96e"
export const ContentSlug4b413c3c10ac4b82Bdc51bcbc603fce4 = "home-page-quote";

// id: "f773641a-cd99-4fc7-a75d-a5e11d6ec83f"
// title: ""
// type: :text
// key: "title"
// parent_id: "ecd1a539-e20f-4a61-b421-3e56bfaf8ccd"
export const TitleF773641aCd994fc7A75dA5e11d6ec83f = "Secondary Marketing Title 2";

// id: "fc75d2e6-da89-476b-8fd0-a1a859102c53"
// title: ""
// type: :text
// key: "title"
// parent_id: "59a4cec2-498a-4306-89fc-2e1ebdbd4f78"
export const TitleFc75d2e6Da89476b8fd0A1a859102c53 = "Resources";

// id: "c5706cc6-56aa-457e-9adb-f0b944e10c23"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "e78135e4-fdc9-453c-829f-41a1cc0a7ea6"
export function LinksC5706cc656aa457e9adbF0b944e10c23(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksC5706cc656aa457e9adbF0b944e10c23", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "9d860754-9240-49c0-919c-678c5aecb13e"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c9d678f7-4050-4b8b-a933-d44f4d128195"
export function Header9d860754924049c0919c678c5aecb13e(props: any) {
  return (
    <MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7 parentTag="Header9d860754924049c0919c678c5aecb13e" {...props} />
  );
}

// id: "2c5f0f62-f71e-4a48-89ca-8f5f2841e4d0"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "fb04fb41-2b24-4e88-ab5f-817aaf1aa6f7"
export function Button2c5f0f62F71e4a4889ca8f5f2841e4d0(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button2c5f0f62F71e4a4889ca8f5f2841e4d0", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "97dced7a-ec48-48bf-8233-64ce92da08fb"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "65858246-3e16-4e5c-968b-13af0d903ae3"
export function HeaderTop97dced7aEc4848bf823364ce92da08fb(props: any) {
  return (
    <DefaultHeaderTopBfe5bd370d174dbaBba9283942725707 parentTag="HeaderTop97dced7aEc4848bf823364ce92da08fb" {...props} />
  );
}

// id: "fa192d50-31a6-4f45-af88-a6b559b9ff2a"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "0bd27f98-6b32-487d-95f4-84c4003262b5"
export const PostSlugFa192d5031a64f45Af88A6b559b9ff2a = "home-page-posts2";

// id: "a0d8b012-9f00-4f17-84b0-946f593a5d41"
// title: ""
// type: :reference
// key: "label"
// parent_id: "da282f3c-d07c-4138-a28a-66ff4e8fe61e"
export function LabelA0d8b0129f004f1784b0946f593a5d41(props: any) {
  return (
    <DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc parentTag="LabelA0d8b0129f004f1784b0946f593a5d41" {...props} />
  );
}

// id: "d8a6bdb1-368d-46a8-8282-1beb4bc05a4d"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "98c4a8d9-7b29-42ed-9ee7-fb8aa29f5759"
export const NavMenuSlugD8a6bdb1368d46a882821beb4bc05a4d = "marketing-primary-nav";

// id: "74d0b590-867d-4558-9ddd-7d60b7822e79"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "140376b8-3a36-4692-86b5-153242c3b23d"
export function WelcomeBack74d0b590867d45589ddd7d60b7822e79(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack74d0b590867d45589ddd7d60b7822e79", parentTag)} {...props} />
  );
}

// id: "b1012bc5-c0d8-4cf5-83dc-60ec3423c645"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "bf6b40bc-df4a-4c82-b42b-24a2b207858f"
export const NavMenuSlugB1012bc5C0d84cf583dc60ec3423c645 = "marketing-primary-nav";

// id: "d3de29c2-acde-4a55-84d6-ebd36807842c"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "4838a919-76d3-4185-8d3e-0336a0bd1482"
export function HeaderTopD3de29c2Acde4a5584d6Ebd36807842c(props: any) {
  return (
    <DefaultHeaderTopBfe5bd370d174dbaBba9283942725707 parentTag="HeaderTopD3de29c2Acde4a5584d6Ebd36807842c" {...props} />
  );
}

// id: "daa022f2-0671-43c2-b61d-ec3b013497f4"
// title: ""
// type: :reference
// key: "header"
// parent_id: "296f3891-7f5e-430e-b189-d12aa0a3d9b1"
export function HeaderDaa022f2067143c2B61dEc3b013497f4(props: any) {
  return (
    <MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7 parentTag="HeaderDaa022f2067143c2B61dEc3b013497f4" {...props} />
  );
}

// id: "1853d77d-29c8-4297-9d97-9b3d9800bcde"
// title: ""
// type: :reference
// key: "header"
// parent_id: "d53e3926-e0fa-444e-8adb-2dbabbdfab68"
export function Header1853d77d29c842979d979b3d9800bcde(props: any) {
  return (
    <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header1853d77d29c842979d979b3d9800bcde" title="Leveling" {...props} />
  );
}

// id: "4903412d-0012-4888-a20a-0a388bd07ba2"
// title: ""
// type: :text
// key: "class"
// parent_id: "da282f3c-d07c-4138-a28a-66ff4e8fe61e"
export const Class4903412d00124888A20a0a388bd07ba2 = "linked-logo";

// id: "040fec2c-dfc1-4f46-a5f6-2cf67c824cf0"
// title: ""
// type: :text
// key: "title"
// parent_id: "1853d77d-29c8-4297-9d97-9b3d9800bcde"
export const Title040fec2cDfc14f46A5f62cf67c824cf0 = "Leveling";

// id: "db4f51c1-d31d-4bd0-83d0-517e06243e62"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "e78135e4-fdc9-453c-829f-41a1cc0a7ea6"
export function GroupflowDb4f51c1D31d4bd083d0517e06243e62(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("GroupflowDb4f51c1D31d4bd083d0517e06243e62", parentTag)} {...props} />
  );
}

// id: "c7c1ab31-6dee-41b5-b140-4706bff90021"
// title: ""
// type: :reference
// key: "header"
// parent_id: "853569de-f07c-4175-8921-40afa1c63077"
export function HeaderC7c1ab316dee41b5B1404706bff90021(props: any) {
  return (
    <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="HeaderC7c1ab316dee41b5B1404706bff90021" title="About Us" {...props} />
  );
}

// id: "dd8ac833-10b9-4c86-b785-91656b792598"
// title: ""
// type: :reference
// key: "header"
// parent_id: "914b7e5a-ac87-4945-9060-b6bbb9af4ad5"
export function HeaderDd8ac83310b94c86B78591656b792598(props: any) {
  return (
    <MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7 parentTag="HeaderDd8ac83310b94c86B78591656b792598" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "37f2755f-cf12-416e-bf40-846fce8ac73a"
// title: ""
// type: :reference
// key: "body"
// parent_id: "140376b8-3a36-4692-86b5-153242c3b23d"
export function Body37f2755fCf12416eBf40846fce8ac73a(props: any) {
  return (
    <MarketingBody02d628df12ec4cbaB39c4815f07bdacb parentTag="Body37f2755fCf12416eBf40846fce8ac73a" {...props} />
  );
}

// id: "0b61b8f1-700e-462c-bbf5-61f99b305b08"
// title: ""
// type: :text
// key: "title"
// parent_id: "bf6b40bc-df4a-4c82-b42b-24a2b207858f"
export const Title0b61b8f1700e462cBbf561f99b305b08 = null;

// id: "454535ed-c82a-44f6-bccb-4ffade887500"
// title: ""
// type: :text
// key: "title"
// parent_id: "6d1aa071-ed64-4929-8d64-6e5aff1e0440"
export const Title454535edC82a44f6Bccb4ffade887500 = "Join Us";

// id: "6824be3a-ef8e-4b97-9cdd-7eafbb3bb31e"
// title: ""
// type: :text
// key: "title"
// parent_id: "545a01bf-7a0f-4ed1-b9fa-9b981b8fdd36"
export const Title6824be3aEf8e4b979cdd7eafbb3bb31e = "Public Events";

// id: "4eca55d0-0708-47af-a524-64ab88d89ece"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "0bd27f98-6b32-487d-95f4-84c4003262b5"
export const ButtonClass4eca55d0070847afA52464ab88d89ece = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "0589b7f9-2ff7-4f9e-a5b7-8064f17a6e61"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "0be07e90-e8ba-43e4-b64b-baba946c59b7"
export function HeaderTop0589b7f92ff74f9eA5b78064f17a6e61(props: any) {
  return (
    <DefaultHeaderTopBfe5bd370d174dbaBba9283942725707 parentTag="HeaderTop0589b7f92ff74f9eA5b78064f17a6e61" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "7225ee47-e97a-45e8-979a-b084495c1c0b"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "140376b8-3a36-4692-86b5-153242c3b23d"
export function Footer7225ee47E97a45e8979aB084495c1c0b(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer7225ee47E97a45e8979aB084495c1c0b" {...props} />
  );
}

// id: "8d2ee88b-4267-4dca-a9cb-29076b6358bc"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "b30e5004-a98b-4f2c-bb83-c4a41bced8dc"
export const ImageUrl8d2ee88b42674dcaA9cb29076b6358bc = "https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png";

// id: "6e918773-d90b-49d5-9cd2-7a6f9f73f433"
// title: ""
// type: :reference
// key: "label"
// parent_id: "a0d92142-8d2c-42a4-a0dd-2aba8d86bb08"
export function Label6e918773D90b49d59cd27a6f9f73f433(props: any) {
  return (
    <DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc parentTag="Label6e918773D90b49d59cd27a6f9f73f433" {...props} />
  );
}

// id: "04dcf941-8ec9-4593-83fa-f0dce1578c60"
// title: ""
// type: :text
// key: "class"
// parent_id: "98c4a8d9-7b29-42ed-9ee7-fb8aa29f5759"
export const Class04dcf9418ec9459383faF0dce1578c60 = "navigation";

// id: "2bc4da98-88ab-459e-a872-de85db99a9eb"
// title: ""
// type: :html
// key: "logo"
// parent_id: "e78135e4-fdc9-453c-829f-41a1cc0a7ea6"
export function Logo2bc4da9888ab459eA872De85db99a9eb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/logo-wide.png" alt="Mystique logo" className={`${parentTag || ""} Logo2bc4da9888ab459eA872De85db99a9eb logo`} />
  );
}

// id: "a0d92142-8d2c-42a4-a0dd-2aba8d86bb08"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "27e22c73-c5d5-4056-876d-a975c37d5a2e"
export function LinkedLogoA0d921428d2c42a4A0dd2aba8d86bb08(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoA0d921428d2c42a4A0dd2aba8d86bb08", parentTag)} label={<DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc parentTag="Label6e918773D90b49d59cd27a6f9f73f433" alt="logo" />} {...props} />
  );
}

// id: "94949d48-8b01-4b07-aefe-a9c783d084d4"
// title: ""
// type: :text
// key: "title"
// parent_id: "dd78fc66-4bc1-44a5-bad9-b84ef9a4a99d"
export const Title94949d488b014b07AefeA9c783d084d4 = "Default Title";

// id: "382ce498-8e13-4a1a-a6a7-2c7f00b91afa"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0589b7f9-2ff7-4f9e-a5b7-8064f17a6e61"
export const NavMenuSlug382ce4988e134a1aA6a72c7f00b91afa = "members-primary-nav";

// id: "e89432f3-162c-4e15-b12d-84f7e3e603f1"
// title: ""
// type: :text
// key: "title"
// parent_id: "165c3a52-a246-493b-a452-614af6656ae9"
export const TitleE89432f3162c4e15B12d84f7e3e603f1 = "Secondary Page";

// id: "7155c289-f755-45ab-92e5-ff7039327589"
// title: ""
// type: :text
// key: "title"
// parent_id: "c7c1ab31-6dee-41b5-b140-4706bff90021"
export const Title7155c289F75545ab92e5Ff7039327589 = "About Us";

// id: "ecd1a539-e20f-4a61-b421-3e56bfaf8ccd"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "4838a919-76d3-4185-8d3e-0336a0bd1482"
export function HeroEcd1a539E20f4a61B4213e56bfaf8ccd(props: any) {
  return (
    <SecondaryMarketingHeroDd78fc664bc144a5Bad9B84ef9a4a99d parentTag="HeroEcd1a539E20f4a61B4213e56bfaf8ccd" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "545a01bf-7a0f-4ed1-b9fa-9b981b8fdd36"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c8b36a7d-bd88-42a5-9339-da5f9cc66d18"
export function Header545a01bf7a0f4ed1B9fa9b981b8fdd36(props: any) {
  return (
    <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header545a01bf7a0f4ed1B9fa9b981b8fdd36" title="Public Events" {...props} />
  );
}

// id: "4819a0c3-5b2d-4dcc-8946-1063e5e7d694"
// title: ""
// type: :text
// key: "alt"
// parent_id: "b30e5004-a98b-4f2c-bb83-c4a41bced8dc"
export const Alt4819a0c35b2d4dcc89461063e5e7d694 = "logo";

// id: "34c140cd-c174-44af-9c90-289a348ef59f"
// title: ""
// type: :text
// key: "label"
// parent_id: "2c5f0f62-f71e-4a48-89ca-8f5f2841e4d0"
export const Label34c140cdC17444af9c90289a348ef59f = "Join Us";

// id: "e187717f-0d2b-4cee-beb3-7a5855e5d95c"
// title: ""
// type: :text
// key: "title"
// parent_id: "12c2be3e-a174-4389-acbf-472b58f04c28"
export const TitleE187717f0d2b4ceeBeb37a5855e5d95c = "Join Us";

// id: "346290c4-7a9c-4d5f-8f9e-48629bcb07bd"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "4db0fe85-3297-4b09-b4f3-990de21edb32"
export const PostSlug346290c47a9c4d5f8f9e48629bcb07bd = "home-page-posts2";

// id: "5c9a933f-3a67-4419-a42c-76ba624a85a0"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "296f3891-7f5e-430e-b189-d12aa0a3d9b1"
export function SecondaryNav5c9a933f3a674419A42c76ba624a85a0(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav5c9a933f3a674419A42c76ba624a85a0", parentTag)} {...props} />
  );
}

// id: "6d1aa071-ed64-4929-8d64-6e5aff1e0440"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f24f6997-3503-400a-8247-196966f65b30"
export function Header6d1aa071Ed6449298d646e5aff1e0440(props: any) {
  return (
    <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header6d1aa071Ed6449298d646e5aff1e0440" title="Join Us" {...props} />
  );
}

// id: "165c3a52-a246-493b-a452-614af6656ae9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4d5c6685-9902-425c-a8c5-5cf98e3f77d5"
export function Header165c3a52A246493bA452614af6656ae9(props: any) {
  return (
    <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="Header165c3a52A246493bA452614af6656ae9" title="Secondary Page" {...props} />
  );
}

// id: "fb7347e1-77e5-429c-bca6-7ffa403d2281"
// title: ""
// type: :text
// key: "title"
// parent_id: "4838a919-76d3-4185-8d3e-0336a0bd1482"
export const TitleFb7347e177e5429cBca67ffa403d2281 = "Contact";

// id: "6de1a12a-15a3-4a7c-80e1-d24fb8e713dc"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "dd8ac833-10b9-4c86-b785-91656b792598"
export const NavMenuSlug6de1a12a15a34a7c80e1D24fb8e713dc = "members-primary-nav";

// id: "bfe5bd37-0d17-4dba-bba9-283942725707"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopBfe5bd370d174dbaBba9283942725707(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopBfe5bd370d174dbaBba9283942725707 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoDa282f3cD07c4138A28a66ff4e8fe61e", parentTag)} url="/" label={<DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc parentTag="LabelA0d8b0129f004f1784b0946f593a5d41" alt="logo" />} className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation98c4a8d97b2942ed9ee7Fb8aa29f5759", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "04074209-8a38-4374-92bb-c984161b6beb"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "e78135e4-fdc9-453c-829f-41a1cc0a7ea6"
export function SocialLinks040742098a38437492bbC984161b6beb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks040742098a38437492bbC984161b6beb flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.facebook.com/profile.php?id=61550585936211">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.instagram.com/mystiqueenmt/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/mystiqueenmt">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "3c622466-bd76-4c8f-ba8f-4776fd756f1f"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "e78135e4-fdc9-453c-829f-41a1cc0a7ea6"
export function SocialLinks3c622466Bd764c8fBa8f4776fd756f1f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks3c622466Bd764c8fBa8f4776fd756f1f flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.facebook.com/profile.php?id=61550585936211">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.instagram.com/mystiqueenmt/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/mystiqueenmt">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "140376b8-3a36-4692-86b5-153242c3b23d"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePage140376b83a36469286b5153242c3b23d(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePage140376b83a36469286b5153242c3b23d page gf-page-layout page gf-page-layout`}>
      {props["header"] || <HomeHeader658582463e164e5c968b13af0d903ae3 parentTag="Header43455f0aE02645c2A7a585f4836c19db" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack74d0b590867d45589ddd7d60b7822e79", parentTag)} {...props} />}
      {props["body"] || <MarketingBody02d628df12ec4cbaB39c4815f07bdacb parentTag="Body37f2755fCf12416eBf40846fce8ac73a" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("QuoteB29a19db5e144101Bc8d94cdfdd2b96e", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer7225ee47E97a45e8979aB084495c1c0b" {...props} />}
    </div>
  );
}

// id: "e78135e4-fdc9-453c-829f-41a1cc0a7ea6"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 footer`}>
      {props["social-links"] || <SocialLinks3c622466Bd764c8fBa8f4776fd756f1f />}
      {props["links"] || <Navigation parentTag={buildClassName("LinksC5706cc656aa457e9adbF0b944e10c23", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo2bc4da9888ab459eA872De85db99a9eb />}
      </div>
      <div className="copyright">
        2024 Mystique Entertainment and RDP Media Company
      </div>
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("GroupflowDb4f51c1D31d4bd083d0517e06243e62", parentTag)} {...props} />}
    </div>
  );
}

// id: "dd78fc66-4bc1-44a5-bad9-b84ef9a4a99d"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHeroDd78fc664bc144a5Bad9B84ef9a4a99d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeroDd78fc664bc144a5Bad9B84ef9a4a99d hero`}>
      <div className="title">
        {props["title"] || Title94949d488b014b07AefeA9c783d084d4}
      </div>
    </div>
  );
}

// id: "fb04fb41-2b24-4e88-ab5f-817aaf1aa6f7"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroFb04fb412b244e88Ab5f817aaf1aa6f7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroFb04fb412b244e88Ab5f817aaf1aa6f7 hero`}>
      <div className="title">
        Join a vibrant community.
        <br />
        Elevate your nightlife escapades to new heights!
      </div>
      <div className="buttons">
        <a href="/join" className="Button">
          Join Us
        </a>
        <a href="/events/public" className="Button">
          Events
        </a>
      </div>
    </div>
  );
}

// id: "25efd17a-aa10-4753-8834-e9a24f339d36"
// title: "Contact"
// type: :html
// key: "contact"
// parent_id: nil
export function Contact25efd17aAa1047538834E9a24f339d36(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Contact25efd17aAa1047538834E9a24f339d36 gf-page-layout`}>
      {props["header"] || <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="HeaderA56b4476A10b467fA5da6b4541ab1fea" {...props} />}
      <main className="contact-main">
        {props.children}
      </main>
      {props["footer"] || <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer6a65005b792c44c58747D98b499d2a46" {...props} />}
    </div>
  );
}

// id: "a56b4476-a10b-467f-a5da-6b4541ab1fea"
// title: ""
// type: :reference
// key: "header"
// parent_id: "25efd17a-aa10-4753-8834-e9a24f339d36"
export function HeaderA56b4476A10b467fA5da6b4541ab1fea(props: any) {
  return (
    <SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482 parentTag="HeaderA56b4476A10b467fA5da6b4541ab1fea" {...props} />
  );
}

// id: "6a65005b-792c-44c5-8747-d98b499d2a46"
// title: "Footer"
// type: :reference
// key: "footer"
// parent_id: "25efd17a-aa10-4753-8834-e9a24f339d36"
export function Footer6a65005b792c44c58747D98b499d2a46(props: any) {
  return (
    <DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6 parentTag="Footer6a65005b792c44c58747D98b499d2a46" {...props} />
  );
}

// id: "c74bdce9-c308-4e62-9f2f-5a51e614b6b5"
// title: ""
// type: :html
// key: "body"
// parent_id: "853569de-f07c-4175-8921-40afa1c63077"
export function BodyC74bdce9C3084e629f2f5a51e614b6b5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <main className={`${parentTag || ""} BodyC74bdce9C3084e629f2f5a51e614b6b5 about-main`}>
      <div className="logo">
        <img src="https://mp1md-pub.s3.amazonaws.com/orgs/mystique/about-bg.jpeg" alt="Night club photo" />
      </div>
      <div className="text">
        <p>
          Welcome to Mystique Entertainment! We are a premier event entertainment company specializing in hosting sophisticated experiences with genuine people at top clubs and venues. As industry experts, we thrive on creating unforgettable nightlife events that keep the party going.
        </p>
        <p>
          With a team of seasoned professionals, we curate a diverse lineup of entertainment, including renowned DJs, themed parties, and much more. Our events are carefully crafted to provide an immersive and energetic atmosphere, catering to music enthusiasts, partygoers, and thrill-seekers alike.
        </p>
        <p>
          Whether you&#39;re looking for a pulsating dance floor, exclusive VIP experiences, or a themed extravaganza, we&#39;ve got you covered. Our dedication to innovation and attention to detail ensures that each event is a one-of-a-kind celebration.   We take pride in our meticulous planning, creative flair, attention to detail and making all of our
          guest feel welcome.
        </p>
        <p>
          Join us for the ultimate nightlife experience, where we fuse cutting-edge production, top-tier talent, and a vibrant community to create memories that will keep you coming back for more. Explore our upcoming events and let us elevate your nightlife escapades to new heights!
        </p>
      </div>
    </main>
  );
}

// id: "0bd27f98-6b32-487d-95f4-84c4003262b5"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCards0bd27f986b32487d95f484c4003262b5(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCards0bd27f986b32487d95f484c4003262b5", parentTag)} postSlug="home-page-posts2" buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

const Components = {
  SecondaryPage4d5c66859902425cA8c55cf98e3f77d5,
  ShortHeader27e22c73C5d54056876dA975c37d5a2e,
  AboutLevelingPageD53e3926E0fa444e8adb2dbabbdfab68,
  BackgroundImageB3cafaba128d454eA5631ea12b68d680,
  Navigation98c4a8d97b2942ed9ee7Fb8aa29f5759,
  Hero7568c77fAd0f4074A755D598980481ad,
  AdminPagesBe96aa09E3c0498dAd96047abef2d332,
  MembersAreaDefault914b7e5aAc8749459060B6bbb9af4ad5,
  DefaultLogoB30e5004A98b4f2cBb83C4a41bced8dc,
  DefaultHeaderBf6b40bcDf4a4c82B42b24a2b207858f,
  PageQuoteBeaebdef95c6446fB53c5bac75818d47,
  MembersAreaHeader0be07e90E8ba43e4B64bBaba946c59b7,
  ResourcesPage10840101126c499b939eD383f5b06e8e,
  MarketingBody02d628df12ec4cbaB39c4815f07bdacb,
  Label2e6cb9efA67b479bA3d9B156e9e6e7d1,
  JoinUsPageF24f69973503400a8247196966f65b30,
  AboutUsPage853569deF07c4175892140afa1c63077,
  MemberPages296f38917f5e430eB189D12aa0a3d9b1,
  Href510d602d210a4a8bBd23B59e2939f4d7,
  Footer760d77cc8bda48c8Aae62f553b66fdfd,
  SignupPagesFfab96080fb04d388bfd70a3eba599ee,
  HeaderF91efe72943f4064Be9549e2cabbd3c7,
  MessagePagesC9d678f740504b8bA933D44f4d128195,
  PublicEventsPageC8b36a7dBd8842a59339Da5f9cc66d18,
  HomeHeader658582463e164e5c968b13af0d903ae3,
  TitleD495e45cFf914f9599c3130fa6583ef3,
  MarketingCards4db0fe8532974b09B4f3990de21edb32,
  FooterA441b9d600ff4d6e84e842121d8853ff,
  LinkedLogoDa282f3cD07c4138A28a66ff4e8fe61e,
  Header12c2be3eA1744389Acbf472b58f04c28,
  Header59a4cec2498a430689fc2e1ebdbd4f78,
  HrefBcbb2f5016d044d7BdcdA8e7a3cf584a,
  NavMenuSlug6f7a02479b954ba0Bd03624e68b777b7,
  BackgroundImageD07ed7df0f35401d887dC8079483d658,
  Footer9e9ac86cE9854243BabfEe111e478d9b,
  QuoteB29a19db5e144101Bc8d94cdfdd2b96e,
  Footer1201dab103774222899967ad168f865f,
  Footer0a4bebb6D6424a5eA2a0B24369aa54e5,
  Url6285255d08f0413aA78e88a5db29942c,
  NavMenuSlug90e780e6Fb5e46d5A3c0917957d5e78c,
  SecondaryMarketingHeader4838a91976d341858d3e0336a0bd1482,
  NavigationA0af3b3573224ca78750Edf79cd723ba,
  Footer30fc08e7Dfd942a6B374C98f8f12ad02,
  Copyright460e3dfb6efb427b9b6915c410c19ffd,
  FooterA794277aAa1347e9Ac35980632b92df3,
  Header43455f0aE02645c2A7a585f4836c19db,
  ContentSlug4b413c3c10ac4b82Bdc51bcbc603fce4,
  TitleF773641aCd994fc7A75dA5e11d6ec83f,
  TitleFc75d2e6Da89476b8fd0A1a859102c53,
  LinksC5706cc656aa457e9adbF0b944e10c23,
  Header9d860754924049c0919c678c5aecb13e,
  Button2c5f0f62F71e4a4889ca8f5f2841e4d0,
  HeaderTop97dced7aEc4848bf823364ce92da08fb,
  PostSlugFa192d5031a64f45Af88A6b559b9ff2a,
  LabelA0d8b0129f004f1784b0946f593a5d41,
  NavMenuSlugD8a6bdb1368d46a882821beb4bc05a4d,
  WelcomeBack74d0b590867d45589ddd7d60b7822e79,
  NavMenuSlugB1012bc5C0d84cf583dc60ec3423c645,
  HeaderTopD3de29c2Acde4a5584d6Ebd36807842c,
  HeaderDaa022f2067143c2B61dEc3b013497f4,
  Header1853d77d29c842979d979b3d9800bcde,
  Class4903412d00124888A20a0a388bd07ba2,
  Title040fec2cDfc14f46A5f62cf67c824cf0,
  GroupflowDb4f51c1D31d4bd083d0517e06243e62,
  HeaderC7c1ab316dee41b5B1404706bff90021,
  HeaderDd8ac83310b94c86B78591656b792598,
  Body37f2755fCf12416eBf40846fce8ac73a,
  Title0b61b8f1700e462cBbf561f99b305b08,
  Title454535edC82a44f6Bccb4ffade887500,
  Title6824be3aEf8e4b979cdd7eafbb3bb31e,
  ButtonClass4eca55d0070847afA52464ab88d89ece,
  HeaderTop0589b7f92ff74f9eA5b78064f17a6e61,
  Footer7225ee47E97a45e8979aB084495c1c0b,
  ImageUrl8d2ee88b42674dcaA9cb29076b6358bc,
  Label6e918773D90b49d59cd27a6f9f73f433,
  Class04dcf9418ec9459383faF0dce1578c60,
  Logo2bc4da9888ab459eA872De85db99a9eb,
  LinkedLogoA0d921428d2c42a4A0dd2aba8d86bb08,
  Title94949d488b014b07AefeA9c783d084d4,
  NavMenuSlug382ce4988e134a1aA6a72c7f00b91afa,
  TitleE89432f3162c4e15B12d84f7e3e603f1,
  Title7155c289F75545ab92e5Ff7039327589,
  HeroEcd1a539E20f4a61B4213e56bfaf8ccd,
  Header545a01bf7a0f4ed1B9fa9b981b8fdd36,
  Alt4819a0c35b2d4dcc89461063e5e7d694,
  Label34c140cdC17444af9c90289a348ef59f,
  TitleE187717f0d2b4ceeBeb37a5855e5d95c,
  PostSlug346290c47a9c4d5f8f9e48629bcb07bd,
  SecondaryNav5c9a933f3a674419A42c76ba624a85a0,
  Header6d1aa071Ed6449298d646e5aff1e0440,
  Header165c3a52A246493bA452614af6656ae9,
  TitleFb7347e177e5429cBca67ffa403d2281,
  NavMenuSlug6de1a12a15a34a7c80e1D24fb8e713dc,
  DefaultHeaderTopBfe5bd370d174dbaBba9283942725707,
  SocialLinks040742098a38437492bbC984161b6beb,
  SocialLinks3c622466Bd764c8fBa8f4776fd756f1f,
  HomePage140376b83a36469286b5153242c3b23d,
  DefaultFooterE78135e4Fdc9453c829f41a1cc0a7ea6,
  SecondaryMarketingHeroDd78fc664bc144a5Bad9B84ef9a4a99d,
  HomePageHeroFb04fb412b244e88Ab5f817aaf1aa6f7,
  Contact25efd17aAa1047538834E9a24f339d36,
  HeaderA56b4476A10b467fA5da6b4541ab1fea,
  Footer6a65005b792c44c58747D98b499d2a46,
  BodyC74bdce9C3084e629f2f5a51e614b6b5,
  MarketingCards0bd27f986b32487d95f484c4003262b5
}

export default Components;